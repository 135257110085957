import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './styles.module.scss'
import { TCustomLink } from "../../../types/common/commonTypes"
import { colors } from '../../../constants/salesStyles'

type IProps = {
    marginLeft?: number
    marginRight?: number
    marginTop?: number
    marginBottom?: number
    color?: colors
}

const CustomLink = ({ upperText, text, href, onClick, target, truncate, marginRight, marginLeft, marginTop, marginBottom, color }: TCustomLink & IProps) => (
    <div className={`${styles.wrap} ${truncate ? styles.truncate : ''}`}>
        {upperText && (
            <div className={`${styles.upperText} ${truncate ? styles.truncate : ''}`}>{upperText}</div>
        )}
        {target
            ? (
                <a
                    href={href}
                    target={target}
                    className={`${styles.headerLink} ${truncate ? styles.truncate : ''}`}
                    onClick={onClick}
                    rel={target == '_blank' ? "noopener noreferrer" : ''}
                    style={{ 
                        marginLeft: marginLeft && marginLeft + 'px',
                        marginRight: marginRight && marginRight + 'px',
                        marginTop: marginTop && marginTop + 'px',
                        marginBottom: marginBottom && marginBottom + 'px',
                        color: color && color,
                    }}
                >
                    {text}
                </a>)
            : (
                <NavLink to={href} className={`${styles.headerLink} ${truncate ? styles.truncate : ''}`} onClick={onClick}>
                    {text}
                </NavLink>
            )
        }


    </div>
)

export default CustomLink
