import React from 'react'
import styles from "./styles.module.scss"
import SalesText from '../../components/_atoms/SalesText/SalesText'
import { colors, fontSizes, fontWeights } from "../../constants/salesStyles"
import PayWallListItem from "../../components/_atoms/PayWallListItem/PayWallListItem"
import PayWallPhone from "../../components/_atoms/PayWallPhone/PayWallPhone"
import ErrorCar from "../../assets/img/error.svg"
import SalesButton from '../../components/_atoms/SalesButton/SalesButton'


const PayWall = () => {
    const listItems = [
        'Функционал приложения ограничен, так как закончилась оплата подписки',
        'Временно отключена публикация объявлений на маркетплейсах и на витрине',
        'Процесс оплаты займет всего минуту',

    ]
    return (
        <div className={styles.wrap}>
            <div className={styles.imageAndContentWrap}>
                <img src={ErrorCar} alt="payWallCar"/>
                <div className={styles.contentWrap}>
                    <SalesText
                        text={'Оплатите подписку'}
                        fontSize={fontSizes.xxl}
                        fontWeight={fontWeights.bold}
                        color={colors.blueDark}
                        marginBottomPixels={40}

                    />
                    <div>
                        {listItems.map((listItem: string) => (
                            <PayWallListItem
                                key={listItem}
                                text={listItem}
                            />
                        ))}
                    </div>
                    <SalesButton
                        backgroundColor={colors.blueDark}
                        marginBottomPixels={40}
                        marginTopPixels={30}
                        paddingHorizontalPixels={20}
                        paddingVerticalPixels={7}
                    >
                        <a href={"https://vas.parts.auto/"} target={"_blank"}>
                            <SalesText
                                text={'Оплатить'}
                                color={colors.white}
                                fontSize={fontSizes.m}
                            />
                        </a>
                    </SalesButton>


                    <PayWallPhone
                        displayPhone={"+7 (909) 6298658"}
                        phoneNumber={"+79096298658"}
                    />
                </div>
            </div>


        </div>
    )
}

export default PayWall
