import { useEffect, useRef, useState } from 'react'
import { useDispatch } from "react-redux"
import { Search, XCircleFill, XLg } from "react-bootstrap-icons"
import styles from './styles.module.scss'
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import { KeyboardEventLocal } from "../../../types/common/commonTypes"
import SalesTextEditable from '../../_molecules/SalesTextEditable/SalesTextEditable'
import { colors, fontSizes } from '../../../constants/salesStyles'

interface IProps {
    oldSearchTerm: string | undefined,
}

const TableHeadPartSearch = ({ oldSearchTerm, }: IProps) => {
    const dispatch = useDispatch()
    const [isEditable, setIsEditable] = useState(false)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [searchTerm, setSearchTerm] = useState<string | undefined>(oldSearchTerm)
    const [renderValue, setRenderValue] = useState('')

    const dispatchSearch = async () => {
        dispatch({
            type: storeDispatchTypes.setPartNameTextFilterParts,
            value: {
                partName: searchTerm
            }
        })
    }

    const abortChange = () => {
        setSearchTerm(oldSearchTerm)
        setRenderValue(oldSearchTerm ? oldSearchTerm : '')
        setIsEditable(false)
        inputRef.current = null
    }

    const clearSearch = () => {
        setSearchTerm('')
        setRenderValue('')
    }

    const keyHandler = (event: KeyboardEventLocal) => {
        switch (true) {
        case event.code === 'Enter' || event.code === 'NumpadEnter' || event.code ===  'Tab':
            setSearchTerm(renderValue)
            setIsEditable(false)
            break
        case event.code === 'Escape':
            abortChange()
            break
        default:
            return
        }
    }

    useEffect(() => {
        if (inputRef.current && isEditable) {
            inputRef.current.focus()
        }
        setRenderValue(oldSearchTerm ? oldSearchTerm : '')
    }, [isEditable])

    useEffect(() => {
        setSearchTerm(oldSearchTerm)
    }, [oldSearchTerm])

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (inputRef.current
                && !inputRef.current.contains(event.target as Node)
            ) {
                setIsEditable(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [inputRef])

    useEffect(() => {
        if(!isEditable && (oldSearchTerm !== searchTerm)) {
            dispatchSearch()
        } else if (oldSearchTerm) {
            setRenderValue(oldSearchTerm)
        }
    }, [isEditable, searchTerm])

    return (
        <div className={styles.wrap}>
            {isEditable
                ? (
                    <span className={styles.selectSearchWrap}>
                        <div
                            ref={inputRef}
                            onKeyDown={keyHandler}
                            onClick={() => {
                                if (isEditable) {
                                    setIsEditable(true)
                                }
                            }}
                            className={styles.input}
                        >
                            <div className={styles.wrap}>
                                <SalesTextEditable
                                    renderValue={renderValue}
                                    onChangeValue={setRenderValue}
                                    isEditable={isEditable}
                                    placeholder={'Введите название'}
                                    backgroundColor={colors.white}
                                    disableAutoFocus={false}
                                    onSubmit={() => setIsEditable(false)}
                                    suffixElement={renderValue
                                    ? <span
                                        onClick={clearSearch}
                                        style={{ marginRight: '10px' }}
                                    >
                                        <XLg color={colors.grayDark} size={fontSizes.m}/>
                                    </span>
                                    : <></>}
                                />
                            </div>
                        </div>
                    </span>
                )
                : (
                    <div>
                        <span
                            onClick={() => setIsEditable(true)}
                            className={`${styles.tableHeadWrap} ${searchTerm ? styles.filtered : ''}`}
                        >
                            {searchTerm ? searchTerm : 'Запчасть'}
                        </span>
                        {searchTerm
                        ? (
                            <span className={styles.icon} onClick={clearSearch}>
                                <XCircleFill color='grey' size={14}/>
                            </span>
                        )
                        : (
                            <span className={styles.icon} onClick={() => setIsEditable(true)}>
                                <Search color={colors.grayBorderApp} size={14}/>
                            </span>
                        )}
                    </div>
                )
            }
        </div>
    )
}

export default TableHeadPartSearch
