import React, { useRef } from "react"
import { Outlet } from "react-router-dom"
import styles from "./styles.module.scss"
import { useAuthGuard } from "../hooks/user/useAuthGuard"
import Sidebar from "../components/_molecules/Sidebar/Sidebar"
import Header from "../components/_orgamisms/Header/Header"
import Footer from "../components/_atoms/Footer/Footer"
import { usePayWall } from "../hooks/user/usePayWall"
import SubscriptionExpiryBanner from "../components/_atoms/SubscriptionExpiryBanner/SubscriptionExpiryBanner"
import { useGetAccountCashierInfo } from "../hooks/user/useGetAccountCashierInfo"

function Admin() {
    useAuthGuard()
    usePayWall()
    useGetAccountCashierInfo()
    const mainPanel = useRef(null)
    
    return (
        <>
            <div className={styles.adminWrap}>
                <Header/>
                <div className={styles.adminWrap__sideBarContent}>
                    <Sidebar/>
                    <div  className={styles.adminWrap__content} ref={mainPanel}>
                        <SubscriptionExpiryBanner/>
                        <Outlet/>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Admin
