import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Dispatch as DispatchRedux } from "redux"
import { useDispatch } from 'react-redux'
import styles from './styles.module.scss'
import SalesTextEditable from "../SalesTextEditable/SalesTextEditable"
import { colors, fontSizes } from "../../../constants/salesStyles"
import { KeyboardEventLocal, SelectOptionLocal } from "../../../types/common/commonTypes"
import SalesSelectSearchSuffix from "../SalesSelectSearchSuffix/SalesSelectSearchSuffix"
import { RootState } from "../../../modules/store/rootReducer"
import SalesSelectOptions from "../SalesSelectOptions/SalesSelectOptions"

interface IProps {
    renderValue: string
    onChangeValue: (newValue: string | number, newName: string) => void
    onChangeManyValues?: (
        newValue: string | number,
        newName: string,
        secondValue?: number | string,
        secondName?: string
    ) => void
    onChangeRenderValue: (newValue: string) => void
    isEditable: boolean
    getSuggestions: (searchTerm: string, filterId?: string) => (dispatch: DispatchRedux, getState: () => RootState) => Promise<void>
    suggestions: SelectOptionLocal[]
    allowEmptySearch?: boolean
    isLoading: boolean 
    maxOptions?: number
    placeholder?: string
    disableAutoFocus?: boolean
    isDisabled?: boolean
    filterId?: string
    errorMessage?: string
    validateRenderValue?: (textValue: string, suggestions: SelectOptionLocal[], errorMessage: string) => void
    widthInPixelsOptions?: number
    onSubmit?: () => void
    delay?: number
    onClose?: () => void
    value?: string | number
    color?: colors
    colorHover?: colors
    backgroundColor?: colors
    backgroundColorHover?: colors
    inputBackgroundColor?: colors
    inputPaddingRight?: number
    suffixColor?: colors
    selectTop?: number
    selectPaddingTop?: number
    selectPaddingBottom?: number
    minSearchLength?: number
    suffixElement?: ReactElement
    searchEnabled?: boolean
    hideSearchSuffix?: boolean
    isDisableFindCurrentOption?: boolean
    optionBeforeText?: JSX.Element
}

const SalesSelectSearch = ({
    renderValue,
    onChangeValue,
    onChangeManyValues,
    onChangeRenderValue,
    isEditable,
    getSuggestions,
    suggestions,
    allowEmptySearch = false,
    isLoading,
    maxOptions = 9999,
    placeholder,
    disableAutoFocus,
    isDisabled,
    filterId,
    errorMessage,
    validateRenderValue,
    widthInPixelsOptions,
    onSubmit,
    delay,
    onClose,
    value,
    color,
    colorHover,
    backgroundColor,
    backgroundColorHover,
    inputBackgroundColor,
    inputPaddingRight,
    suffixColor,
    selectTop,
    selectPaddingTop,
    selectPaddingBottom,
    minSearchLength = 0,
    suffixElement,
    searchEnabled = true,
    hideSearchSuffix = false,
    isDisableFindCurrentOption,
    optionBeforeText,
}: IProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const dispatchLocal = useDispatch()
    const divRef = useRef<HTMLDivElement | null>(null)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const [currentOption, setCurrentOption] = useState(0)
    const findCurrentOption = () => suggestions.findIndex((suggestion) => suggestion.name.toLocaleLowerCase() === renderValue.toLocaleLowerCase())

    const updateField = (newValue: string | number, newName: string, secondValue?: number | string, secondName?: string) => {
        if (onChangeManyValues) {
            onChangeManyValues(newValue, newName, secondValue, secondName)
        } else {
            onChangeValue(newValue, newName)
        }
        getSuggestionsLocal(newName)
    }

    const getSuggestionsLocal = (newValue: string, preventSearch?: boolean) => {
        if (!newValue?.length && !allowEmptySearch) return
        if (!preventSearch && newValue?.length >= minSearchLength && searchEnabled) {
            if (filterId) {
                dispatchLocal(getSuggestions(newValue, filterId))
            } else {
                dispatchLocal(getSuggestions(newValue))
            }
        }
    }

    const updateRenderValue = (newValue: string, preventSearch?: boolean) => {
        onChangeRenderValue(newValue)

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
            if (newValue !== renderValue) {
                getSuggestionsLocal(newValue, preventSearch)
            }
        }, delay)
    }

    const nextOption = () => {
        if (currentOption === maxOptions - 1 || currentOption === suggestions.length - 1) {
            return
        }
        if (isOpen) {
            updateRenderValue(suggestions[currentOption + 1].name, true)
            setCurrentOption(currentOption + 1)
        }
    }

    const prevOption = () => {
        if (currentOption == 0) {
            return
        }
        if (isOpen) {
            updateRenderValue(suggestions[currentOption - 1].name, true)

            setCurrentOption(currentOption - 1)
        }
    }

    const keyHandler = (event: KeyboardEventLocal) => {
        switch (true) {
        case event.code === 'ArrowDown':
            nextOption()
            break

        case event.code === 'ArrowUp':
            prevOption()
            break

        case !isDisabled && (event.code === 'Space' || event.code === 'Backspace' || event.code === 'Delete'):
            setIsOpen(true)
            break

        case event.code === 'Enter' || event.code === 'NumpadEnter':
            if (isOpen) {
                updateField(suggestions[currentOption].value, suggestions[currentOption].name, suggestions[currentOption].subValue, suggestions[currentOption].subName)
                setIsOpen(false)
            } else {
                setIsOpen(true)
            }
            break

        case event.code === 'Escape':
            setIsOpen(false)
            break

        default:
            if (isEditable && !isOpen && !isDisabled) {
                setIsOpen(true)
            }
            return
        }
    }

    useEffect(() => {
        if (isOpen && renderValue.length === 0) {
            getSuggestionsLocal('')
        } else if (isOpen && renderValue.length > 0 && !suggestions.find(suggestion => suggestion.name === renderValue)) {
            getSuggestionsLocal(renderValue)
        }
    }, [isOpen])

    useEffect(() => {
        if (isEditable && !isDisabled && !disableAutoFocus) {
            setIsOpen(true)
        }
    }, [isEditable])

    useEffect(() => {
        if (isOpen && !isDisableFindCurrentOption) {
            setCurrentOption(findCurrentOption())
        }
    }, [isOpen, suggestions])

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (divRef.current
                && !divRef.current.contains(event.target as Node)
            ) {
                onClose && onClose()
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [divRef])

    useEffect(() => {
        if (!isOpen && validateRenderValue) {
            validateRenderValue(renderValue, suggestions, 'выберите значение из списка')
        }
    }, [renderValue, isOpen, isEditable])

    useEffect(() => {
        const index = suggestions.findIndex((suggestion) => `${suggestion.value}` === `${value}`)
        setCurrentOption(index)
    }, [value, suggestions])

    return (
        <div
            ref={divRef}
            onKeyDown={keyHandler}
            tabIndex={0}
            onClick={() => {
                if (isEditable && !isOpen && !isDisabled) {
                    setIsOpen(true)
                }
            }}
            className={styles.wrap}
        >
            <SalesTextEditable
                renderValue={renderValue}
                placeholder={placeholder}
                onChangeValue={updateRenderValue}
                isEditable={isEditable}
                disableAutoFocus={disableAutoFocus}
                isDisabled={isDisabled}
                errorMessage={errorMessage}
                inputPaddingRight={inputPaddingRight}
                suffixElement={
                    <>
                        {suffixElement}
                        {!hideSearchSuffix && (
                            <SalesSelectSearchSuffix
                                text={renderValue}
                                isLoading={isLoading}
                                clearText={() => updateField('', '', '', '')}
                                color={suffixColor}
                            />
                        )}
                    </>
                }
                onSubmit={onSubmit}
                backgroundColor={inputBackgroundColor}
            />
            {(!isOpen || !searchEnabled)
                ? null
                : <SalesSelectOptions
                    hideOptions={() => setIsOpen(false)}
                    noOptionsText={!allowEmptySearch && renderValue.length < minSearchLength ? 'введите запрос' : 'ничего не найдено'}
                    options={suggestions}
                    updateField={updateField}
                    maxOptions={maxOptions}
                    fontSize={fontSizes.xs}
                    fontSizeSubName={fontSizes.xxxs}
                    currentOption={currentOption}
                    widthInPixels={widthInPixelsOptions}
                    color={color}
                    colorHover={colorHover}
                    backgroundColor={backgroundColor}
                    backgroundColorHover={backgroundColorHover}
                    top={selectTop}
                    paddingTop={selectPaddingTop}
                    paddingBottom={selectPaddingBottom}
                    showOptions={renderValue.length >= minSearchLength}
                    optionBeforeText={optionBeforeText}
                />
            }

        </div>
    )
}

export default SalesSelectSearch

