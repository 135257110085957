import { useDispatch } from "react-redux"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
    ArrowLeft,
} from "react-bootstrap-icons"
import styles from "./styles.module.scss"
import { useSelector } from "../../modules/store/customSelector"
import loadPartList from "../../modules/redux/partList/LoadPartList"
import CategoryIcon from "../../components/_atoms/CategogyIcon/CategoryIcon"
import { countDaysInStock, formatDate, num_word } from "../../utils/converters"
import CellThumbCarousel from "../../components/_orgamisms/CellThumbCarousel/CellThumbCarousel"
import ListPagination from "../../components/_atoms/ListPagination/ListPagination"
import CellEditPrice from "../../components/_atoms/CellEditPrice/CellEditPrice"
import ListSkeleton from "../../components/_atoms/ListSkeleton/ListSkeleton"
import { SortFieldParts, SortIconType } from "../../types/common/commonTypes"
import ColumnSort from "../../components/_molecules/ColumnSort/ColumnSort"
import { colWidth } from "../../utils/stylePatches"
import ListNoItems from "../../components/_atoms/ListNoItems/ListNoItems"
import TableHeadCategorySearchParts from "../../components/_molecules/TableHeadCategorySearchParts/TableHeadCategorySearchParts"
import TableHeadMarkSearchParts from "../../components/_molecules/TableHeadMarkSearchParts/TableHeadMarkSearchParts"
import TableHeadModelSearchParts from "../../components/_molecules/TableHeadModelSearchParts/TableHeadModelSearchParts"
import TableHeadPartNumberSearchParts from "../../components/_molecules/TableHeadPartNumberSearchParts/TableHeadPartNumberSearchParts"
import SalesText from "../../components/_atoms/SalesText/SalesText"
import { fontSizes, fontWeights } from "../../constants/salesStyles"
import TableHeadPartSearch from "../../components/_atoms/TableHeadPartSearch/TableHeadPartSearch"
import TableHeadRangeFilter from "../../components/_atoms/TableHeadRangeFilter/TableHeadRangeFilter"
import { storeDispatchTypes } from "../../modules/store/storeDispatchTypes"
import HoverOverlay from "../../components/_atoms/HoverOverlay/HoverOverlay"
import setPartArchivedStatus from "../../modules/redux/partList/setPartArchivedStatus"
import Badge from "../../components/_atoms/Badge/Badge"
import useResetFiltersOnUnmount from "../../hooks/partList/useResetFiltersOnUnmount"
import SearchComponent from "../../components/_molecules/SearchComponent/SearchComponent"
import NavigationButtons from "../../components/_molecules/NavigationButtons/NavigationButtons"
import { adminRouteAlias, getAdminNavigationPath } from "../../router/adminRouteAlias"
import { pages } from "../../constants/parts/navigationButtonsPages"
import CopyButton from "../../components/_atoms/CopyButton/CopyButton"

const ArchivedPartList = () => {
    const dispatch = useDispatch()
    const [perPage] = useState(50)

    const navigate = useNavigate()
    let pagesParts = pages

    const isLoading = useSelector((state) => state.partList.isLoading.partList)
    const parts = useSelector((state) => state.partList.partList.data)

    // Sort
    const sortOrder = useSelector((state) => state.partList.sortOrder)

    // Car filter
    const carFilter = useSelector((state) => state.partList.filters.car)

    //Select search filter
    const modelId = useSelector((state) => state.partList.filters.modelId)
    const markId = useSelector((state) => state.partList.filters.markId)
    const categoryId = useSelector((state) => state.partList.filters.categoryId)
    const partId = useSelector((state) => state.partList.filters.partId)
    const partNumber = useSelector((state) => state.partList.filters.partNumber)

    // search filter part title
    const partName = useSelector((state) => state.partList.filters.partName)

    // Range Filters
    const priceRange = useSelector((state) => state.partList.filters.priceRange)
    const priceFrom = useSelector((state) => state.partList.filters.priceRange?.from)
    const priceTo = useSelector((state) => state.partList.filters.priceRange?.to)

    const yearRange = useSelector((state) => state.partList.filters.yearRange)
    const yearFrom = useSelector((state) => state.partList.filters.yearRange?.from)
    const yearTo = useSelector((state) => state.partList.filters.yearRange?.to)
    const page = useSelector((state) => state.partList.filters.page)

    // Counts
    const [counts, setCounts] = useState<Record<string, number>>()
    const itemsCount = useSelector(state => state.partList.partList.dataCount)
    const itemsTotal = useSelector(state => state.partList.partList.total)
    const draftCount = useSelector(state => state.partList.partList.draftCount)
    const archivedCount = useSelector(state => state.partList.partList.archivedCount)
    const soldCount = useSelector(state => state.partList.partList.soldCount)
    
    useEffect(() => {
        if (!isLoading) {
            setCounts({
                itemsTotal,
                draftCount,
                archivedCount,
                soldCount,
            })
        }
    }, [isLoading, itemsTotal, draftCount, archivedCount, soldCount])

    useEffect(() => {
        pagesParts = pagesParts.map(page => ({
            ...page,
            count: counts && counts[page.countKey],
        }))
    }, [counts])

    // Параметры поиска
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchParamsLoaded, setSearchParamsLoaded] = useState(0)
    const searchParamCarId = searchParams.get('carId')
    const searchParamPage = searchParams.get('p')
    const searchParamPartName = searchParams.get('pn')
    const searchParamCategoryId = searchParams.get('g')
    const searchParamMarkId = searchParams.get('markId')
    const searchParamModelId = searchParams.get('modelId')
    const searchParamPriceFrom = searchParams.get('priceFrom')
    const searchParamPriceTo = searchParams.get('priceTo')
    const searchParamPartNumber = searchParams.get('partNumber')
    const searchParamSortOrder = searchParams.get('sortOrder')

    const filterPartsByPartName = (partName: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNameTextFilterParts,
            value: {
                partName: partName
            }
        })
    }

    const filterPartsByCategoryId = (categoryId: string) => {
        dispatch({
            type: storeDispatchTypes.setCategoryFilterParts,
            value: {
                filterId: categoryId
            }
        })
    }

    const filterPartsByMarkId = (markId: string) => {
        dispatch({
            type: storeDispatchTypes.setMarkFilterParts,
            value: {
                filterId: markId,
            }
        })
    }

    const filterPartsByModelId = (modelId: string) => {
        dispatch({
            type: storeDispatchTypes.setModelFilterParts,
            value: {
                filterId: modelId,
            }
        })
    }

    const filterPartsByPriceRange = (priceFrom: string | null, priceTo: string | null) => {
        dispatch({
            type: storeDispatchTypes.setPriceRangeParts,
            value: {
                from: priceFrom,
                to: priceTo,
            }
        })
    }

    const filterPartsByPartNumber = (partNumber: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNumberFilterParts,
            value: {
                filterId: partNumber,
            }
        })
    }

    const filterPartsBySortOrder = (sortField: string, sortOrder: string) => {
        dispatch({
            type: storeDispatchTypes.setPartListSort,
            value: {
                sortField,
                sortOrder,
            }
        })
    }

    // Загрузка параметров из строки поиска
    useEffect(() => {
        if (searchParamPage !== `${page}` && (searchParamPage !== null || page !== 1)) {
            dispatch({
                type: storeDispatchTypes.setPageFilterParts,
                value: { page: Number(searchParamPage) > 1 ? Number(searchParamPage) : 1 }
            })
        }

        if (searchParamPartName !== partName && (searchParamPartName || partName !== undefined)) {
            filterPartsByPartName(searchParamPartName ? searchParamPartName : '')
        }

        if (searchParamCategoryId !== `${categoryId}` && (searchParamCategoryId || categoryId !== undefined)) {
            filterPartsByCategoryId(searchParamCategoryId ? searchParamCategoryId : '')
        }

        if (searchParamMarkId !== `${markId}` && (searchParamMarkId || markId !== undefined)) {
            filterPartsByMarkId(searchParamMarkId ? searchParamMarkId : '')
        }

        if (searchParamModelId !== `${modelId}` && (searchParamModelId || modelId !== undefined)) {
            filterPartsByModelId(searchParamModelId ? searchParamModelId : '')
        }

        if (
            (searchParamPriceFrom !== `${priceFrom}` || (searchParamPriceFrom === null && priceFrom !== undefined)) ||
            (searchParamPriceTo !== `${priceTo}` || (searchParamPriceTo === null && priceTo !== undefined))
        ) {
            if (searchParamPriceFrom || priceFrom !== undefined || searchParamPriceTo || priceTo !== undefined) {
                filterPartsByPriceRange(searchParamPriceFrom, searchParamPriceTo)
            }
        }

        if ((searchParamPartNumber !== `${partNumber}`) && (searchParamPartNumber || partNumber !== undefined)) {
            filterPartsByPartNumber(searchParamPartNumber ? searchParamPartNumber : '')
        }

        if (searchParamSortOrder && (searchParamSortOrder !== sortOrder.map(pair => pair.join(',')).join(','))) {
            const searchParamsSet = new Set(searchParamSortOrder.match(/[^,]+,[^,]+/g))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            // Для каждой пары из sortOrder, которая не содержится в searchParamSortOrder
            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    filterPartsBySortOrder(pair[0], 'NONE')
                }
            })

            // Для каждой пары из searchParamSortOrder, которая не содержится в sortOrder
            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    const [field, order] = param.split(',')
                    filterPartsBySortOrder(field, order)
                }
            })
        } else if (!searchParamSortOrder) {
            for (const field of sortOrder) {
                filterPartsBySortOrder(field[0], 'NONE')
            }
        }

        if (searchParamCarId !== `${carFilter?.carId[0]}`) {
            if (!searchParamCarId) {
                dispatch({ type: storeDispatchTypes.resetCarFilterParts })
            } else {
                dispatch({
                    type: storeDispatchTypes.setCarFilterParts,
                    value: {
                        car: {
                            carId: [searchParamCarId],
                        }
                    }
                })
            }
        } else if (!carFilter?.carId[0]) {
            dispatch({ type: storeDispatchTypes.resetCarFilterParts })
        }

        setSearchParamsLoaded(1)
    }, [searchParams])

    // Загрузка параметров в строку поиска
    useEffect(() => {
        if (!searchParamsLoaded) {
            return
        }

        // Текущие параметры
        const currentPage = page > 1 ? `${page}` : null
        const currentCarId = carFilter?.carId ? `${carFilter.carId}` : null
        const currentPartName = partName ? `${partName}` : null
        const currentCategoryId = categoryId ? `${categoryId}` : null
        const currentMarkId = markId ? `${markId}` : null
        const currentModelId = modelId ? `${modelId}` : null
        const currentPriceFrom = priceFrom ? `${priceFrom}` : null
        const currentPriceTo = priceTo ? `${priceTo}` : null
        const currentPartNumber = partNumber ? `${partNumber}` : null
        const currentSortOrder = sortOrder.length ? sortOrder : null

        // Проверяем, изменились ли параметры строки поиска
        const pageChanged = searchParamPage !== currentPage
        const carIdChanged = searchParamCarId !== currentCarId
        const partNameChanged = searchParamPartName !== currentPartName
        const categoryIdChanged = searchParamCategoryId !== currentCategoryId
        const markIdChanged = searchParamMarkId !== currentMarkId
        const modelIdChanged = searchParamModelId !== currentModelId
        const priceFromChanged = searchParamPriceFrom !== currentPriceFrom
        const priceToChanged = searchParamPriceTo !== currentPriceTo
        const partNumberChanged = searchParamPartNumber !== currentPartNumber

        let sortOrderChanged = false
        if (searchParamSortOrder) {
            const searchParamsSet = new Set(searchParamSortOrder.match(/[^,]+,[^,]+/g))
            const sortOrderSet = new Set(sortOrder.map(pair => pair.join(',')))

            sortOrder.forEach(pair => {
                const pairString = pair.join(',')
                if (!searchParamsSet.has(pairString)) {
                    sortOrderChanged = true
                    return
                }
            })

            searchParamsSet.forEach(param => {
                if (!sortOrderSet.has(param)) {
                    sortOrderChanged = true
                    return
                }
            })
        } else if (!searchParamSortOrder && currentSortOrder) {
            sortOrderChanged = true
        }

        const searchParamsChanged =
        carIdChanged || pageChanged ||  partNameChanged ||
        categoryIdChanged || markIdChanged || modelIdChanged || priceFromChanged || priceToChanged ||
        partNumberChanged || sortOrderChanged

        if (searchParamsChanged) {
            setSearchParams({
                ... (carFilter?.carId.length ? { carId: carFilter?.carId[0] || '' } : {}),
                ... (page > 1 ? { p: '' + page } : {}),
                ... (partName ? { pn: '' + partName } : {}),
                ... (categoryId ? { g: '' + categoryId } : {}),
                ... (markId ? { markId: '' + markId } : {}),
                ... (modelId ? { modelId: '' + modelId } : {}),
                ... (priceFrom ? { priceFrom: '' + priceFrom } : {}),
                ... (priceTo ? { priceTo: '' + priceTo } : {}),
                ... (partNumber ? { partNumber: '' + partNumber } : {}),
                ... ((sortOrder && sortOrder.length !== 0) ? { sortOrder: '' + sortOrder } : {}),
            })
        }

        // load parts list data
        dispatch(
            loadPartList({
                pagination: {
                    page,
                    perPage,
                },
                order: sortOrder,
                carId: carFilter?.carId,
                modelId,
                markId,
                groupId: categoryId,
                search: partName,
                priceRange: (priceRange && { from: priceFrom, to: priceTo }),
                yearRange: (yearRange && { from: yearFrom, to: yearTo }),
                partId,
                partNumber,
                isArchived: true,
            })
        )
    }, [
        searchParamsLoaded,
        page,
        perPage,
        sortOrder,
        carFilter?.carId,
        modelId,
        markId,
        categoryId,
        partName,
        priceFrom,
        priceTo,
        yearFrom,
        yearTo,
        partId,
        partNumber,
    ])

    const filteredParts = useMemo(
        () =>
            parts.filter((part) => {
                const { isArchived } = part
                return isArchived
            }),
        [parts]
    )

    // Главный поиск
    const setNameSearch = (searchTerm: string) => {
        dispatch({
            type: storeDispatchTypes.setPartNameTextFilterParts,
            value: {
                partName: searchTerm
            }
        })
    }

    // Сброс фильтров при уходе со страницы
    useResetFiltersOnUnmount()

    return (
        <div className={styles.wrap}>
            <div className={styles.rowWrap}>
                <SalesText
                    text={'Запчасти в архиве'}
                    fontWeight={fontWeights.bold}
                    fontSize={fontSizes.xxl}
                    letterSpacing={-0.02}
                />
            </div>
            <div className={styles.rowWrap}>
                <SearchComponent oldSearchTerm={partName} setSearchTerm={setNameSearch}/>
            </div>
            <div className={styles.rowWrap}>
                <NavigationButtons pages={pagesParts} currentPage={getAdminNavigationPath(adminRouteAlias.archivedParts.location)} counts={counts} navigate={navigate}/>
                {!isLoading && parts.length === 0
                    ? <></>
                    : <div className={styles.wrapRight}>
                        <ListPagination
                            page={page}
                            setPage={(page) =>
                                dispatch({
                                    type: storeDispatchTypes.setPageFilterParts,
                                    value: { page },
                                })
                            }
                            perPage={perPage}
                            itemsCount={itemsCount}
                        />
                    </div>}
            </div>
            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(54)}>
                            <TableHeadCategorySearchParts />
                        </th>
                        <th scope="col" style={colWidth(204)}>
                            <TableHeadPartSearch oldSearchTerm={partName} />
                        </th>
                        <th
                            scope="col"
                            style={colWidth(100)}
                            className={`${carFilter ? styles.filtered : ""}`}
                        >
                            {carFilter ? (
                                carFilter.markTitle
                            ) : (
                                <TableHeadMarkSearchParts />
                            )}
                        </th>
                        <th
                            scope="col"
                            style={colWidth(200)}
                            className={`${carFilter ? styles.filtered : ""}`}
                        >
                            {carFilter ? (
                                carFilter.modelTitle
                            ) : (
                                <TableHeadModelSearchParts />
                            )}
                        </th>
                        <th scope="col" style={colWidth(35)}>
                            Фото
                        </th>
                        <th scope="col" style={colWidth(103)}>
                            <TableHeadPartNumberSearchParts />
                        </th>
                        <th
                            scope="col"
                            style={colWidth(95)}
                        >
                            <TableHeadRangeFilter
                                initialTableHead={"Цена"}
                                step={1000}
                                leftInPixes={-35}
                                initialFromVal={priceFrom}
                                initialToVal={priceTo}
                                widthInPixels={150}
                                storeActionSetRange={
                                    storeDispatchTypes.setPriceRangeParts
                                }
                                isColumnSort
                            />
                        </th>
                        <th scope="col" style={colWidth(85)}>
                            Дата
                            <ColumnSort
                                iconType={SortIconType.NUMERIC}
                                sortField={SortFieldParts.UPDATED_AT}
                                slice={"partList"}
                                reverseSort={true}
                            />
                        </th>
                        <th scope="col" style={colWidth(99)}>
                            Действия
                        </th>
                    </tr>
                </thead>
                {isLoading || !parts ? (
                    <ListSkeleton
                        rowCount={9}
                        columnCount={9}
                        marginVerticalRem={0.21}
                    />
                ) : (
                    <tbody>
                        {filteredParts.map((part, index) => (
                            <tr key={index} className={styles.row}>
                                <td scope="row">
                                    <CategoryIcon categoryId={part.groupId} />
                                </td>
                                <td scope="row">
                                    {part.customTitle
                                        ? part.customTitle
                                        : part.title}
                                </td>
                                <td scope="row">{part.markTitle}</td>
                                <td scope="row">{part.modelTitle}</td>
                                <td scope="row">
                                    <CellThumbCarousel pictures={part.images} part={part}/>
                                </td>
                                <td scope="row" className={styles.default}>
                                    <CopyButton
                                        copyText={part.partNumber}
                                        overlayText={'ОЕМ скопирован в буфер обмена'}
                                        isText
                                    />
                                </td>
                                <td scope="row">
                                    <CellEditPrice
                                        id={part.id}
                                        price={part.price}
                                    />
                                </td>
                                <td scope="row" className={styles.alignRight}>
                                    <div>
                                        <Badge
                                            text={formatDate(part.updatedAt)}
                                            color='#ddd'
                                            fontColor='#555'
                                        />
                                    </div>
                                    {+countDaysInStock(part.updatedAt) > 10 ?
                                        <div>
                                            <Badge
                                                text={countDaysInStock(part.updatedAt) + ' ' + num_word(+countDaysInStock(part.updatedAt), ['день', 'дня', 'дней'])}
                                                color='#eee'
                                                fontColor='#777'
                                            />
                                        </div> : ''}
                                </td>
                                <td>
                                    <HoverOverlay tooltipText={"Возврат"}>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => dispatch(setPartArchivedStatus(part.id, false))} 
                                            className="d-flex align-items-center"
                                        >
                                            <ArrowLeft
                                                color={"white"}
                                                size={18}
                                                />
                                        </Button>
                                    </HoverOverlay>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </Table>
            {!isLoading && parts.length === 0
                ? <ListNoItems slice={'partList'} />
                : <ListPagination
                    page={page}
                    setPage={(page) =>
                        dispatch({
                            type: storeDispatchTypes.setPageFilterParts,
                            value: { page },
                        })
                    }
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent={'flex-end'}
                />}
        </div>
    )
}

export default ArchivedPartList
