import { Dispatch } from 'redux'
import { storeDispatchTypes } from "../../store/storeDispatchTypes"
import { RootState } from "../../store/rootReducer"
import { apiInstance } from "../../api/apiInstance"
import removeFromListDelay from "../../../constants/removeFromListDelay"
import anchor from '../../../constants/anchor'

const setPartSoldStatus = (partId: string, newSoldStatus = true) => async(
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<void> => {
    const { userData } = getState()
    try {
        const { data: { status } } = await apiInstance.post('/setPartSoldStatus', {
            userId:  userData.smsAuth.userId,
            partId,
            newSoldStatus
        }, {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`
            }
        })
        if(status === 'ok') {
            dispatch(({
                type: storeDispatchTypes.setPartSoldStatus,
                value: {
                    id: partId,
                    isSold: newSoldStatus,
                },
            }))

            setTimeout(() => {
                dispatch(({
                    type: storeDispatchTypes.removePartFormListByPartId,
                    value: {
                        id: partId,
                    }
                }))
            }, removeFromListDelay)
        }
    } catch (e) {
        console.error('set part sold status failed', e)
    }
}

export default setPartSoldStatus
