import React from 'react'
import { useDispatch } from "react-redux"
import { XCircleFill } from "react-bootstrap-icons"
import styles from './styles.module.scss'
import { SortFieldCars, SortFieldParts, SortIconColor, SortIconType, SortOrder } from '../../../types/common/commonTypes'
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"
import { useSelector } from "../../../modules/store/customSelector"
import SortIcon from "../../_atoms/SortIcon/SortIcon"


interface IProps {
    iconType: SortIconType
    sortField: SortFieldParts | SortFieldCars
    reverseSort?: boolean,
    slice: 'partList' | 'carList',
    isResetButton?: boolean
    handleSortChange?: (isActive: boolean) => void
}

const sortOrderNext = (sortOrderCurrent: string) => {
    switch (true) {
    case sortOrderCurrent === SortOrder.NONE:
        return SortOrder.DESC
    case sortOrderCurrent === SortOrder.DESC:
        return SortOrder.ASC
    case sortOrderCurrent === SortOrder.ASC:
        return SortOrder.NONE
    default:
        return sortOrderCurrent
    }
}

const iconColor = (sortOrderCurrent: string) => {
    if (sortOrderCurrent === SortOrder.NONE) {
        return SortIconColor.INACTIVE
    } else {
        return SortIconColor.ACTIVE
    }
}

const ColumnSort = ({ iconType, sortField, reverseSort, slice, isResetButton = true, handleSortChange }: IProps) => {

    let sortOrderCurrentEl
    let sortOrderCurrentIndex = 1
    switch (slice) {
    case 'partList':
        sortOrderCurrentEl = useSelector((state) => state.partList.sortOrder.find(item => item[0] === sortField))
        sortOrderCurrentIndex += useSelector((state) => state.partList.sortOrder.findIndex(item => item[0] === sortField))
        break
    case 'carList':
        sortOrderCurrentEl = useSelector((state) => state.carList.sortOrder.find(item => item[0] === sortField))
        sortOrderCurrentIndex += useSelector((state) => state.carList.sortOrder.findIndex(item => item[0] === sortField))
        break
    }

    let actionType: storeDispatchTypes
    switch (slice) {
    case 'partList':
        actionType = storeDispatchTypes.setPartListSort
        break
    case 'carList':
        actionType = storeDispatchTypes.setCarListSort
        break
    }


    const sortOrderCurrent = sortOrderCurrentEl ? sortOrderCurrentEl[1] : SortOrder.NONE

    const sortOrder = sortOrderNext(sortOrderCurrent)

    const dispatch = useDispatch()
    const changeSort = () => {
        dispatch({
            type: actionType,
            value: {
                sortField,
                sortOrder
            }
        })
        if (sortOrder !== SortOrder.NONE && handleSortChange) {
            handleSortChange(true)
        }
    }

    const resetSort = () => {
        dispatch({
            type: actionType,
            value: {
                sortField,
                sortOrder: SortOrder.NONE
            }
        })
    }


    return (
        <span className={styles.wrap}>
            <span onClick={changeSort} className={styles.sortIcon}>
                <SortIcon
                    sortOrder={sortOrderCurrent}
                    iconType={iconType}
                    color={iconColor(sortOrderCurrent)}
                    size={18}
                    reverseSort={reverseSort}
                />
            </span>
            {sortOrderCurrent !== SortOrder.NONE && isResetButton && (
                <>
                    <span className={styles.clearIcon} onClick={resetSort}>
                        <XCircleFill color='grey' size={14}/>
                    </span>
                    <span className={styles.sortIndex}>
                        {sortOrderCurrentIndex}
                    </span>
                </>
            )
            }
        </span>
    )
}

export default ColumnSort
