import React, { useState } from 'react'
import { ArrowCounterclockwise, Trash3, XCircleFill } from "react-bootstrap-icons"
import styles from "./styles.module.scss"
import OverlayWithText from "../OverlayWithText/OverlayWithText"

interface IProps {
    id: string,
    publicUrl: string;
    markImg: (id: string) => void;
    unmarkImg: (id: string) => void;
    index: number;
    setActiveImageIndex: (index: number) => void
}

const ModalImageThumb = ({ id, publicUrl, markImg, unmarkImg, index, setActiveImageIndex }: IProps) => {
    const [isToDelete, setIsToDelete] = useState(false)
    return (
        <div className={styles.wrap}>
            <img
                src={publicUrl}
                alt=''
                // className={`${id === activeImageId ? styles.active : ''}`}
                onClick={() => {
                    setActiveImageIndex(index)
                }}
            />
            {!isToDelete
                ?(
                    <button
                        onClick={() => {
                            markImg(id)
                            setIsToDelete(true)
                        }}
                        className={styles.deleteButton}
                    >
                        <div>
                            <div className={styles.iconFill}/>
                            <XCircleFill color='grey' size={14}/>
                        </div>
                    </button>
                )
                :(
                    <OverlayWithText backgroundBootstrapColor={'bg-danger'}>
                        <Trash3 color='white' size={40}/>
                        <button onClick={() => {
                            unmarkImg(id)
                            setIsToDelete(false)
                        }}
                        className={styles.revertButton}
                        >
                            <ArrowCounterclockwise color='white' size={10}/>
                        </button>
                    </OverlayWithText>
                )
            }
        </div>
    )
}

export default  ModalImageThumb
