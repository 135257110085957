import { Button, Modal } from 'react-bootstrap'
import { useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './styles.module.scss'
import { initialPartState } from './initialPartState'
import { AppDispatch } from '../../../modules/store/customDispatch'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import Loader from '../../_atoms/Loader/Loader'
import { partReducer } from '../../../pages/NewPart/partReducer'
import PartCreateUpdateTable from '../PartCreateUpdateTable/PartCreateUpdateTable'
import { fieldValuePair, Part, partCreateUpdateActionTypes, partFields, partInput, PartListSearchParams, partSpecialPropsFields, PartTypeData } from '../../../types/part/partTypes'
import { carFields } from '../../../types/car/carTypes'
import changePartTypeDataForParts from '../../../modules/redux/partList/ChangePartTypeDataForParts'

interface IProps {
    isOpen: boolean
    closeModal: () => void
    parts: Part[]
    confirmChanges: () => void
    partListSearchParams?: PartListSearchParams
}

const ModalChangePartTypeData = ({ isOpen, closeModal, parts, confirmChanges, partListSearchParams }: IProps) => {
    const dispatchRedux = useDispatch<AppDispatch>()
    const [state, dispatch] = useReducer(partReducer, initialPartState)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onCloseModal = () => {
        closeModal()
    }

    const confirm = async () => {
        setIsLoading(true)
        const newPartTypeData = await getApiObjectFromState()
        if (newPartTypeData) {
            await dispatchRedux(changePartTypeDataForParts(parts.map(part => part.id), newPartTypeData, partListSearchParams))
            confirmChanges()
            onCloseModal()
        }
        setIsLoading(false)
    }

    const getField = (fieldToSearch: carFields | partFields) => state.fields.find((field: partInput) => field.field === fieldToSearch)

    const setIsDisabledFields = () => state.fields.map((field: partInput) => {
        const selectSearchText = field.specialProps?.renderValue
        const errorMessage = field.value ? '' : field.specialProps?.errorMessage

        if (field.specialProps?.fieldParent) {
            const fieldParentValue = getField(field.specialProps.fieldParent).value
            const fieldParentValueInChild = field.specialProps?.[partSpecialPropsFields.fieldParentValue]
            const isEnabled = fieldParentValue
            const isParentChanged = fieldParentValueInChild && fieldParentValue !== fieldParentValueInChild && !field.specialProps.isAdded

            return {
                ...field,
                value: isParentChanged ? '' : field.value,
                specialProps: {
                    ...field.specialProps,
                    [partSpecialPropsFields.renderValue]: isParentChanged ? '' : selectSearchText,
                    [partSpecialPropsFields.isDisabled]: !isEnabled,
                    [partSpecialPropsFields.fieldParentValue]: isEnabled ? fieldParentValue : '',
                    [partSpecialPropsFields.errorMessage]: isEnabled ? errorMessage : '',
                }
            }
        } else {
            return field
        }
    })

    const getVisibleAny = (isVisibleAnyFields: fieldValuePair[]) => {
        let isVisible = false
        isVisibleAnyFields.forEach((item: fieldValuePair) => {
            if (getField(item.field).value === item.value) {
                isVisible = true
                return
            }
        })
        return isVisible
    }

    const getVisibleEvery = (isVisibleAllFields: fieldValuePair[][]) => {
        let isVisible = true
        for (const array of isVisibleAllFields) {
            if (!getVisibleAny(array)) isVisible = false
        }
        return isVisible
    }

    const getVisibleFields = () =>
        state.fields.filter((field: partInput) => {
            if (field.specialProps?.isVisibleEvery) {
                return getVisibleEvery(field.specialProps?.isVisibleEvery)
            } else if (field.specialProps?.isVisibleSwitch && field.specialProps?.isVisibleAny) {
                return getVisibleAny(field.specialProps?.isVisibleSwitch) && getVisibleAny(field.specialProps?.isVisibleAny)
            } else if (field.specialProps?.isVisibleSwitch && !field.specialProps?.isVisibleAny) {
                return getVisibleAny(field.specialProps?.isVisibleSwitch)
            } else if (field.specialProps?.isVisibleAny && !field.specialProps?.isVisibleSwitch) {
                return getVisibleAny(field.specialProps?.isVisibleAny)
            } else {
                return true
            }
        })

    const getVisibleColumn = (column: number) => getVisibleFields().filter((field: partInput) => field.specialProps?.column === column)


    const getIsSubmitDisabled = () => {
        let isDisabled = false
        getVisibleFields().forEach((field: partInput) => {
            if (field?.specialProps?.errorMessage) {
                isDisabled = true
            }
        })
        setIsSubmitDisabled(isDisabled)
    }

    const getApiObjectFromState = async () => {
        let apiObject = {
            groupId: 0,
            typeId: 0,
        }
        let isObjectReady = true

        getVisibleFields().map((item: partInput) => {
            if (item.value && !item?.specialProps?.errorMessage && !item?.specialProps?.extraHandle) {
                apiObject = { ...apiObject, [item.field]: item.value }
            } else if (item.value && item?.specialProps?.extraHandle === 'wheels') {
                let partApiObject = { ...apiObject } as PartTypeData
                const itemField = item.field === 'PartBrand' ? 'Brand' : item.field

                partApiObject = {
                    ...partApiObject,
                    extraData: {
                        ...partApiObject.extraData,
                        [itemField]: item.value
                    }
                }

                apiObject = { ...partApiObject }
            } else if (item?.specialProps?.isRequired && (!item.value)) {
                dispatch({
                    type: partCreateUpdateActionTypes.updateSpecialProps,
                    value: {
                        field: item.field,
                        specialProps: {
                            ...item.specialProps,
                            [partSpecialPropsFields.errorMessage]: 'поле обязательно к заполнению'
                        }
                    }
                })
                isObjectReady = false
            } else if (item?.specialProps?.errorMessage) {
                isObjectReady = false
            }
        })

        if (isObjectReady) {
            return apiObject
        } else {
            setIsSubmitDisabled(true)
        }
    }

    useEffect(() => {
        getIsSubmitDisabled()
        dispatch({
            type: partCreateUpdateActionTypes.initializeState,
            value: { state: { fields: setIsDisabledFields() } }
        })
    }, [
        ...state.fields.map((field: partInput) => field.value)
    ])

    return (
        <Modal
            show={isOpen}
            onHide={onCloseModal}
            centered
            dialogClassName={styles.modal}
        >
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Header closeButton>
                <Modal.Title>
                    <b>Изменить категорию</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PartCreateUpdateTable
                    dispatch={dispatch}
                    fields={getVisibleColumn(1)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={confirm} disabled={isSubmitDisabled}>Сохранить</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalChangePartTypeData
