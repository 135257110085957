import { Button, Form, Modal } from 'react-bootstrap'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from "./styles.module.scss"
import { useSelector } from '../../../modules/store/customSelector'
import SelectableMartketplaceList from '../../_atoms/SelectableMarketplaceList/SelectableMartketplaceList'
import { Part } from '../../../types/part/partTypes'
import setPartSaleInfo from '../../../modules/redux/partList/setPartSaleInfo'
import { RegisterCheckByTitleAndPrice } from '../../../utils/kkmserver'
import Loader from '../../_atoms/Loader/Loader'
import receipt from '../../../assets/icons/receipt.png'

interface IProps {
    part: Part | null;
    isOpen: boolean;
    closeModal: () => void;
}

const ModalSellPart = ({ part, isOpen, closeModal }: IProps) => {
    const dispatch = useDispatch()

    const [selectedMarketplace, setSelectedMarketplace] = useState<string | null>(null)
    const [price, setPrice] = useState<string | undefined>(part?.price.toString())
    const [isLoading, setIsLoading] = useState(false)

    const marketPlacesInfo = useSelector((state) => state.sales.marketplaces.info)
    const cashierInfo = useSelector(state => state.userData.cashierInfo)

    const onCloseModal = () => {
        closeModal()
    }

    const handleComponentClick = (index: string) => {
        setSelectedMarketplace(index)
    }

    const confirmSoldPart = () => {
        if(!price || !part || !selectedMarketplace) return

        dispatch(setPartSaleInfo(part, Number(price), selectedMarketplace))
        closeModal()
    }

    const handleReceiptClick = async () => {
        setIsLoading(true)
        part && price && RegisterCheckByTitleAndPrice(part.customTitle, Number(price), cashierInfo?.cashier)
        setIsLoading(false)
    }

    return (
        <>
            <Modal
                className={styles.modalSellPart}
                show={isOpen}
                onHide={onCloseModal} 
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {part?.customTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className={styles.title}>Цена</h4>
                    <Form.Control
                        value={price}
                        type="number"
                        id="price"
                        aria-describedby="price"
                        className={styles.priceField}
                        placeholder='3 000 P'
                        onChange={(e) => setPrice(e.target.value)}
                    />
                    <h4 className={styles.title}>Площадка продажи</h4>
                    <SelectableMartketplaceList
                        componentData={marketPlacesInfo}
                        selectedComponent={selectedMarketplace}
                        onComponentClick={handleComponentClick}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.buttonGroup}>
                        {cashierInfo?.connectCashbox
                            ? isLoading
                                ? <Loader diameterInPx={37} thicknessInPx={3}/>
                                : <img src={receipt} alt='receipt' className={styles.icon} onClick={handleReceiptClick}/>
                            : null
                        }
                        <Button
                            size='lg'
                            variant="primary"
                            onClick={confirmSoldPart}
                            disabled={!price || !selectedMarketplace}
                        >
                            Продано
                        </Button>
                        <Button 
                            size='lg'
                            variant="secondary" 
                            onClick={onCloseModal}
                        >
                            Отменить
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalSellPart
