import React, { Dispatch, ReactElement } from 'react'
import { AnyAction } from "redux"
import { partFields, partInput } from "../../../types/part/partTypes"
import SalesCardRowPart from "../SalesCardRowPart/SalesCardRowPart"
import SalesRow from '../../_atoms/SalesRow/SalesRow'

interface IProps {
    fields: (partInput) []
    dispatch: Dispatch<AnyAction>
    top?: ReactElement,
    bottom?: ReactElement,
    modalImageGroup?: ReactElement,
}

const PartCreateUpdateTable = ({ fields, dispatch, top, bottom, modalImageGroup }: IProps) => (<table>
    <tbody>
        {top && top}
        {fields.map((item, index) => {
            if (item.field === partFields.partImages && modalImageGroup) {
                return (
                    <>
                        <SalesRow>
                            {modalImageGroup}
                        </SalesRow>
                        <SalesCardRowPart
                            key={item.field + item.title}
                            input={item}
                            isLast={index === fields.length - 1}
                            dispatch={dispatch}
                        />
                    </>
                )
            } else {
                return <SalesCardRowPart
                key={item.field + item.title}
                input={item}
                isLast={index === fields.length - 1}
                dispatch={dispatch}
            />
        }})}
        {bottom &&
            <SalesRow
                removeBorderTop={true}
            >
                {bottom}
            </SalesRow>
        }
    </tbody>
</table>)

export default PartCreateUpdateTable
