import { Dispatch } from 'redux'
import { apiInstance } from '../../api/apiInstance'
import { SelectOptionLocal } from "../../../types/common/commonTypes"
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const getAllTiresBrands = (searchTerm: string) => async (dispatch: Dispatch): Promise<void> => {

    dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'tiresBrands', value: true } })

    try {
        const { data: { status, data } } = await apiInstance.post('/dicts/avito/TiresBrands')
        const tiresBrands: SelectOptionLocal[] = []

        if (status === 'ok') {
            const parser = new DOMParser()
            const xmlDoc = parser.parseFromString(data, "text/xml")
            const brandsNodes = xmlDoc.getElementsByTagName('Brand')

            for (let i = 0; i < brandsNodes.length; i++) {
                const brandName = brandsNodes[i].getAttribute('name')
                if (brandName && brandName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                    tiresBrands.push({ name: brandName, value: brandName })
                }
            }

        }
        dispatch({ type: storeDispatchTypes.setAllTiresBrands, value: { tiresBrands } })

    } catch (e) {
        console.error('load tires brands failed', e)
    }

    dispatch({ type: storeDispatchTypes.setPartsLoader, value: { field: 'tiresBrands', value: false } })

}

export default getAllTiresBrands
