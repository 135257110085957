import { v4 as uuidV4, v5 as uuidV5 } from 'uuid'

import { Part } from "../../types/part/partTypes"

class StickersApiService {
    private readonly apiUrl: string

    constructor() {
        this.apiUrl = "https://stickers.parts.auto"
        // this.apiUrl = "http://localhost:7001"
    }

    public getEncodeUrlFromPart(part: Part) {
        if (!part) {
            return null
        }

        return `${this.apiUrl}/encode?part_id=${part.id}&sign=${this.getSingFromPart(part)}`
    }

    public getDecodeUrlByStickerId(stickerId: string) {
        if (!stickerId) {
            return null
        }

        return `${this.apiUrl}/decode?sticker_id=${stickerId}`
    }

    private getSingFromPart(part: Part) {
        return uuidV5(JSON.stringify(part), uuidV4())
    }
}

export default new StickersApiService()
