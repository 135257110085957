import React from 'react'
import { useDispatch } from 'react-redux'
import styles from './styles.module.scss'
import { storeDispatchTypes } from "../../../modules/store/storeDispatchTypes"

interface IProps {
    slice?: 'carList' | 'partList' | 'documentList'
    message?: string
}

const ListNoItems = ({ slice, message }: IProps) => {
    const dispatch = useDispatch()
    let actionType: storeDispatchTypes.resetFiltersCars | storeDispatchTypes.resetFiltersParts | storeDispatchTypes.resetFiltersDocuments
    switch (slice) {
    case 'carList':
        actionType = storeDispatchTypes.resetFiltersCars
        break
    case 'partList':
        actionType = storeDispatchTypes.resetFiltersParts
        break
    case 'documentList':
        actionType = storeDispatchTypes.resetFiltersDocuments
        break
    }
    const resetFilters = () => {
        dispatch({
            type: actionType,
        })
    }
    return (
        <div className={styles.wrap}>
            <p>Ничего не найдено</p>
            {message && <p>{message}</p>}
            {slice && <p>Попробуйте <span className={styles.reset} onClick={resetFilters}>сбросить фильтры</span></p>}
        </div>
    )
}

export default ListNoItems
