import React, { useRef, useState } from 'react'
import { Button, Dropdown, Stack } from "react-bootstrap"
import { ArrowLeft, Trash3 } from "react-bootstrap-icons"
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import styles from './styles.module.scss'
import setPartDeletedStatus from "../../../modules/redux/partList/SetPartDeletedStatus"
import HoverOverlay from "../HoverOverlay/HoverOverlay"
import setSellPartModal from '../../../modules/redux/partList/SetSellPartModal'
import setPartArchivedStatus from '../../../modules/redux/partList/setPartArchivedStatus'
import handshake from '../../../assets/icons/5c7afe00ced3c77617e06033_handshake-icon-p-800.png'
import setPartSoldStatus from '../../../modules/redux/partList/SetPartSoldStatus'
import receipt from '../../../assets/icons/receipt.png'
import clone from '../../../assets/icons/clone-icon-24.png'
import { RegisterCheckByParts } from '../../../utils/kkmserver'
import DatahubApiService from '../../../modules/api/DatahubApiService'
import Loader from '../Loader/Loader'
import { useSelector } from '../../../modules/store/customSelector'

interface IProps {
    partId: string;
    deleteOnly: boolean;
    isSoldPartList?: boolean
}

const CellButtonsParts = ({ partId, deleteOnly = false, isSoldPartList = false }: IProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const soldRef = useRef<HTMLButtonElement | null>(null)
    const [isLoadingCashier, setIsLoadingCashier] = useState(false)
    const [isLoadingClonePart, setIsLoadingClonePart] = useState(false)
    const cashierInfo = useSelector(state => state.userData.cashierInfo)

    const handleReceiptClick = async () => {
        setIsLoadingCashier(true)
        const part = await DatahubApiService.getPartByPartId(partId, false, true)
        part && RegisterCheckByParts([part], cashierInfo?.cashier)
        setIsLoadingCashier(false)
    }

    const handleClonePart = async () => {
        setIsLoadingClonePart(true)
        const part = await DatahubApiService.getPartByPartId(partId, false, true)
        navigate(`/admin/newPart`, { state: { part } })
        setIsLoadingClonePart(false)
    }

    if (isSoldPartList) {
        return (
            <Stack direction='horizontal' gap={2}>
                <HoverOverlay tooltipText={'Вернуть из проданного'}>
                    <Button
                        variant='danger'
                        size='sm'
                        onClick={() => dispatch(setPartSoldStatus(partId, false))}
                        className='d-flex align-items-center'
                    >
                        <ArrowLeft color={'white'} size={18}/>
                    </Button>
                </HoverOverlay>
                {isLoadingClonePart
                    ? <Loader diameterInPx={26} thicknessInPx={3}/>
                    : <HoverOverlay tooltipText={'Дублировать'}>
                        <Button
                            variant='secondary'
                            size='sm'
                            onClick={handleClonePart}
                            className='d-flex align-items-center'
                            style={{ margin: 0 }}
                        >
                            <img src={clone} alt='clone' className={styles.cloneIcon} />
                        </Button>
                    </HoverOverlay>
                }
                {cashierInfo?.connectCashbox
                    ? isLoadingCashier
                        ? <Loader diameterInPx={36} thicknessInPx={3}/>
                        : <img src={receipt} alt='receipt' className={styles.icon} onClick={handleReceiptClick}/>
                    : null
                }
            </Stack>
        )
    }

    if (deleteOnly) {
        return (
            <HoverOverlay tooltipText={'Удалено'}>
                <Button
                    variant="danger"
                    size="sm"
                    onClick={() => dispatch(setPartDeletedStatus(partId))}
                    className="d-flex align-items-center"
                >
                    <Trash3 color={'white'} size={18}/>
                </Button>
            </HoverOverlay>
        )
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="danger" size="sm" className="d-flex align-items-center">
                    <Trash3 color={'white'} size={18}/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <HoverOverlay tooltipText={'Архивировано'}>
                        <Dropdown.Item eventKey="1" onClick={() => dispatch(setPartArchivedStatus(partId))}>В архив</Dropdown.Item>
                    </HoverOverlay>
                    <HoverOverlay tooltipText={'Удалено'}>
                        <Dropdown.Item eventKey="2" onClick={() => dispatch(setPartDeletedStatus(partId))}>Удалить</Dropdown.Item>
                    </HoverOverlay>
                </Dropdown.Menu>
            </Dropdown>
            <HoverOverlay tooltipText={'Продано'} preventWrapFullWidth>
                <Button
                    variant="success"
                    size="sm"
                    onClick={() => dispatch(setSellPartModal({ partId, isOpen: true }))}
                    className="d-flex align-items-center"
                    ref={soldRef}
                >
                    {/* <Check color={'white'} size={18}/> */}
                    <img src={handshake} alt='handshake' className={styles.successBtn}/>
                </Button>
            </HoverOverlay>
        </>
    )
}

export default CellButtonsParts
